<template>
  <div class="container-right">
      <div class="container-right-name">
        个人信息
      </div>
      <div class="top-info">
        <div class="account-info">
          <div class="face"><span class="icon iconfont">&#xe65e;</span></div>
          <div class="name">{{!!userInfo&&!!userInfo.nickname?userInfo.nickname:'-'}}</div>
          <!-- <div class="openVip" @click="onGoVip">{{vipInfo?constants.vipType[vipInfo.vipType]:'开通VIP'}}<span class="icon iconfont">&#xe660;</span></div> -->
        </div>
        <div class="data-info">
          <div class="ant-row">
            <div class="ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-8">
              <div class="block">
                <div class="icon iconPhone"></div>
                <div class="title">手机号</div>
                <p class="p">{{!!userInfo&&!!userInfo.mobile?userInfo.mobile:'-'}}</p>
                <!-- <a @click="onOpenResetPwd">修改</a> -->
              </div>
            </div>
            <div class="ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-8">
              <div class="block">
                <div class="icon iconLock"></div>
                <div class="title">密码</div>
                <p class="p">******</p>
                <a @click="onOpenResetPwd">修改</a>
              </div>
            </div>
            <div class="ant-col ant-col-md-24 ant-col-lg-12 ant-col-xl-8">
              <div class="block">
                <div class="icon iconID"></div>
                <div class="title">实名认证</div>
                <p class="p" v-if="authInfo">已认证</p>
                <p class="p" v-if="!authInfo">未认证</p>
                <router-link v-if="!authInfo" :to="{path:'/Mine/RealName'}">去认证</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-info">
        <a-row>
            <a-col
              :md="24"
              :lg="12"
              :xl="6"
              v-for="(item,index) in list"
              :key="index"
            >
              <div class="block"  @click="goto(item,index)">
                <span class="icon iconfont" v-html="item.iconbg"></span>
                <div class="desc">
                  <h3>{{item.number}}</h3>
                  <p>{{item.desc}}</p>
                </div>
              </div>
            </a-col>
        </a-row>
      </div>
      <reset-pwd
      v-if="isResetPwdVisible"
      @close="onResetPwdClose"
    ></reset-pwd>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import resetPwd from './components/resetPwd'
import constants from '@/utils/constants'
import { queryEvidenceSummary } from "@/api/EvidenceService"
import { queryShopUserSummary } from "@/api/EvidenceService"
import {getUserBalance} from '@/api/ObtainEvidence'
export default {
  name: 'BaseInfo',
    components: {
    resetPwd
  },
   data () {
    return {
      constants,
      isResetPwdVisible:false,
      list:[
      //   {
      //     iconbg:'&#xe64c;',
      //     number:'0',
      //     desc:'我的存证',
      //     router: '/Mine/ExistingEvidence',
      //     key: 'MineExistingEvidence'
      //   },
      //  {
      //     iconbg:'&#xe64e;',
      //     number:'0',
      //     desc:'我的登记',
      //     router: '/Mine/MyRegister',
      //     key: 'MyRegister'
      //   },
        // {
        //   iconbg:'&#xe64b;',
        //   number:'0',
        //   desc:'我的监测',
        //   router: '/Mine/Monitor',
        //   key: 'MineMonitor'
        // },
        // {
        //   iconbg:'&#xe645;',
        //   number:'0',
        //   desc:'我的取证',
        //   router: '/Mine/ObtainEvidence',
        //   key: 'MineObtainEvidence'
        // },
        // {
        //   iconbg:'&#xe63f;',
        //   number:'0',
        //   desc:'钱包（元）',
        //   router: '/Mine/MoneyBag',
        //   key: 'MoneyBag'
        // },
        // {
        //   iconbg:'&#xe638;',
        //   number:'0',
        //   desc:'合约挂卖',
        //   router: '/Mine/OnSale',
        //   key: 'MineOnSale'
        // },
        // {
        //   iconbg:'&#xe636;',
        //   number:'0',
        //   desc:'我的卖单',
        //   router: '/Mine/OnSale',
        //   key: 'MineOnSale',
        //   query: {activeName:2}
        // },
        // {
        //   iconbg:'&#xe636;',
        //   number:'0',
        //   desc:'我的买单',
        //   router: '/Mine/OnSale',
        //   key: 'MineOnSale',
        //   query: {activeName:3}
        // },
      ],
      refName:'GuaranteesBlock'
    }
  },
 computed: {
    ...mapGetters(['userInfo','vipInfo','authInfo','mid'])
  },
  created() {
    // this.init();
  },
  methods: {
    init(){
      let that=this;
      queryEvidenceSummary().then(({data})=>{
        let list=that.list;
        list[0].number=data.existCount;
        list[1].number=data.registerCount;
        list[2].number=data.detectCount;
        list[3].number=data.obtainCount;
      })
      getUserBalance(this.mid).then(({data})=>{
         console.log("我的余额:",data);
        let list=that.list;
        list[4].number=data.cny;
      });
      queryShopUserSummary().then(({data})=>{
        let list=that.list;
        list[5].number=data.goodsCount;
        list[6].number=data.saleCount;
        list[7].number=data.buyCount;
      })
    },
    onGoVip(){
      this.$router.push({
            path: "/Vip",
            query: {}
          });
    },
      onOpenResetPwd () {
      this.isResetPwdVisible = true
    },
    onResetPwdClose () {
      this.isResetPwdVisible = false
    },


    goto (item, index) {
      this.$router.push({
        path: item.router,
        query: item.query,
      });
    }
  }
}
</script>

<style lang='scss' scoped>
@import '../../../assets/css/mine.scss';
.top-info{
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 109, 117, 0.06);
  border-radius: 8px;
  .account-info{
    height: 96px;
    background: linear-gradient(270deg, #007DFF  0%, #145FED 100%);
    border-radius: 8px 8px 0px 0px;
    padding: 24px 0px 40px 88px;
    color: #FFFFFF;
    position: relative;
    .face{
      position: absolute;
      top: 24px;
      left: 24px;
      width: 48px;
      height: 48px;
      background: #FFFFFF;
      line-height: 48px;
      border-radius: 100%;
      .icon{font-size: 48px;color: #007DFF;}
      }
    .name{font-size: 16px;margin-top: -2px;font-weight: 600;color: #fff;}
    .openVip{
      cursor: pointer;
      padding: 2px 16px;
      background: #FFFFFF;
      color:#007DFF;
      box-sizing: border-box;
      display: initial;
      border-radius: 20px;
      font-size: 14px;
      .icon{font-size: 16px;}
    }
  }
  .data-info{
    .block{
      position: relative;
      padding: 18px 0px 16px 88px;
      margin: 24px auto;
    }
    .icon{
          width:48px;
          height: 48px;
          line-height: 9999em;
          overflow: hidden;
          margin-right:24px;
          position: absolute;
          left: 24px;
          top: 16px;
    }
    .icon.iconPhone{
        background:url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/mine-mobile-fill.svg') no-repeat;
        background-size:auto 44px;
    }
    .icon.iconLock{
        background:url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/mine-lock-fill.svg') no-repeat;
        background-size:auto 44px;
    }
    .icon.iconID{
        background:url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/mine-id-fill.svg') no-repeat;
        background-size:auto 44px;
    }

  }
}
.bottom-info{
  margin-top: 32px;
  .block{
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 109, 117, 0.06);
    border-radius: 8px;
    position: relative;
    padding: 16px 0 24px;
    margin: 0 16px 16px 0;
    cursor: pointer;
  }
  .ant-col:nth-child(4n){
    .block{margin: 0 0px 16px 0;}
  }
  .icon{
    position: absolute;
    left: 24px;
    top: 16px;
    font-size: 36px;
    opacity: 0.1;
  }
  .desc{
    h3{
    font-size: 32px;
    color: #007DFF;
    }
    p{
    font-size: 16px;
    color: rgba(0, 18, 22, 0.85);
    }
  }
  
}
 /deep/ .container-right{padding: 0 48px 0;}
</style>
