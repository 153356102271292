<template>
  <div>
    <el-dialog
      width="460px"
      center
      title="修改密码"
      :visible.sync="visible"
      @closed="onClose"
    >
     <div class="resetPwd">
         <el-form ref="form"  :model="form" :rules="rules" :show-message="false">
                 <el-form-item prop="mobileOrEmail">
                  <el-input v-model="form.mobileOrEmail" :disabled="true" :placeholder="userInfo.mobile">
                     <i  slot="prefix" class="iconfont"> &#xe641; </i>
                  </el-input>
                </el-form-item>
                  <el-form-item prop="verifyCode">
                  <el-input type="number" v-model="form.verifyCode" placeholder="请输入短信验证码">
                    <i  slot="prefix" class="iconfont"> &#xe647; </i>
                    <em slot="suffix" class="yzm" @click="sendVerifyCode"> {{sendBtnText}}  </em>
                  </el-input>
                </el-form-item>
                 <el-form-item prop="oldPassword">
                  <el-input v-model="form.oldPassword" type="password" placeholder="请输入旧密码">
                     <i  slot="prefix" class="iconfont"> &#xe65d; </i>
                  </el-input>
                </el-form-item>
                  <el-form-item prop="newPassword">
                  <el-input v-model="form.newPassword" type="password" placeholder="请输入新密码">
                     <i  slot="prefix" class="iconfont"> &#xe65d; </i>
                  </el-input>
                </el-form-item>
                   <el-form-item>
                    <el-button type="primary" @click="onSubmit">确定</el-button>
                  </el-form-item>
              </el-form>  
     </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {editPassword} from '@/api/UserService'
import {sendSmsVerifyCodeOnLine} from '@/api/SmsService'
export default {
  name: "resetPwd",

  data() {
    return {
      // 弹框是否显示
      visible: false,
       sendBtnText:'发送验证码',
        sendStatus:-1,
       form:{
          mobileOrEmail:'',
          verifyCode:'',
          oldPassword:'',
          newPassword:'',
        },
         rules: {
          verifyCode:[
            { required: true, message: '请输入短信验证码', trigger: 'blur' }
          ],
           oldPassword:[
            { required: true, message: '请输入旧密码', trigger: 'blur' }
          ],
           newPassword:[
            { required: true, message: '请输入新密码', trigger: 'blur' }
          ]
         }
    };
  },
  created() {
    this.visible = true
    this.init();
  },
     computed: {
    ...mapGetters(['mid','userInfo'])
  },
  methods: {
    init() {
    
    },
     sendVerifyCode(){
      if(this.sendStatus!=-1){
        return false;
      }
      // if(!this.form.mobileOrEmail){
      //   this.$message.error('请填写手机号码');
      //   return
      // }
      this.sendStatus=0;
      this.sendBtnText='发送中';
       if(!this.mid){
          this.$message.error('异常,未登录状态');
         return
       }
      sendSmsVerifyCodeOnLine(this.mid,'edit-pwd').then(({data,res})=>{
          this.form.verifyCodeId=data;
          this.sendStatus=1;

          let that=this;
          let count=90;
          let refreshVerifyCode=()=>{
              if(count==0){
                 that.sendBtnText='重新发送';
                 that.sendStatus=-1;
                 return false;
              }else{
                 count--;
                 that.sendBtnText=`${count}秒后重发`;
                 setTimeout(refreshVerifyCode,1000);
              }
          }
          setTimeout(refreshVerifyCode,1000);
      }).catch(e=>{
        this.sendBtnText='发送验证码';
        this.sendStatus=-1;
        console.log(e);
      });
    },
    // 关闭弹框时触发的事件
    onClose() {
      this.$emit("close");
    },
    async onSubmit(){
        try {
        const formBaseResult = await this.$refs['form'].validate()
      } catch (error) {
        // eslint-disable-next-line
        console.error(error)
        return
      }
         const params={
              ...this.form,
              // mobileOrEmail:`+86-${this.form.mobileOrEmail}`,
              pwdType:"01"
          }
         editPassword(this.mid,params).then((data)=>{
        window.location.reload()
      }).catch(e=>{
          console.log(e);
      });
    }
  },
};
</script>

<style lang='scss' scoped>
 .resetPwd{
      .el-form{
      margin-top:16px;
    }
    /deep/ .el-input__prefix{
      left:8px;
    }
    /deep/ .el-input__suffix{
      right:8px;
    }
    .yzm{
       color:#007DFF;
       font-style: normal;
       cursor: pointer;
    }
    .iconfont{
      font-size: 22px;
      color: #415558;
    }
    /deep/ .el-input--prefix .el-input__inner{
      padding-left: 36px;
    }
    /deep/ .el-checkbox__label{
      font-weight: normal;
    }
    /deep/ .el-button{
      width:100%;
    }
    .goLogin{
      display: flex;
      justify-content: space-between;
      a{
        color:#007DFF;
      }
    }
 }
</style>
