import request from '@/utils/request'


export function applyObtainOrder(params) {
    return request(
        'post',
        '/v1',
       `/evidence/obtain`,
       params
    );
}


export function paymentObtainOrder(orderNo) {
    return request(
        'post',
        '/v1',
       `/evidence/obtain/payment/${orderNo}`,
        {}
    );
}


export function queryObtainOrder(orderNo) {
    return request(
        'get',
        '/v1',
       `/evidence/obtain/${orderNo}`
    );
}

export function queryObtainOrders(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/evidence/obtain/p/${pageNumber}-${pageSize}`,
       params
    );
}



// 搜索原创-行业知识服务列表
export function queryProductCategory(parentId){
    return request(
        'get',
        '/v1',
       `/shop/product/category/${parentId}`
    );
}
// 搜索原创-查询搜索结果列表
export function queryProducts(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/shop/product/p/${pageNumber}-${pageSize}`,
       params
    );
}

// 个人中心-合约管理
    // 挂卖管理
    export function queryUserProducts(pageNumber,pageSize,params) {
        return request(
            'post',
            '/v1',
           `/shop/product/user/p/${pageNumber}-${pageSize}`,
           params
        );
    }

    // 我的卖单
//     参数：      saleGoodsFilter:{
//         categoryId:null,
//         name: null,
//         status:'21',
//         startTime:null,
//         endTime:null
//       },
    export function queryUserSaleGoods(pageNumber,pageSize,params) {
        return request(
            'post',
            '/v1',
           `/shop/order/user/p/${pageNumber}-${pageSize}`,
           params
        );
    }

    // 我的买单 /shop/order/p/${pageNumber}-${pageSize} post
    export function queryBuyOrder(pageNumber,pageSize,params) {
        return request(
            'post',
            '/v1',
           `/shop/order/p/${pageNumber}-${pageSize}`,
           params
        );
    }

// 个人中心-用户余额
    export function getUserBalance (mid) {
        return request(
            'get',
            '/v1',
           `/ma/account/info/${mid}/cny`,
        )
     }


// 账单明细
// v1/evidence/payment/p/{pageNumber}-{pageSize}
// {"startDate": "2022-01-25",
// "subject": ""}
export function queryBillList(pageNumber,pageSize,params) {
    return request(
        'post',
        '/v1',
       `/evidence/payment/p/${pageNumber}-${pageSize}`,
       params
    );
}
